import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {KundeEntitiesActions} from '../actions/kunde-entities.actions';
import {concatMap, of, switchMap, take, tap} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import {MappedHttpErrorResponse, mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {SentryActions} from '../actions/sentry.actions';
import {ActionCreator, Store} from '@ngrx/store';
import {TypedAction} from '@ngrx/store/src/models';
import {mappedHttpErrorResponse} from '../../operator-services/mapped-http-error-response';
import {KundeEntitiesSelectors} from '../selectors/kunde-entities.selectors';
import {AppState} from '../states/app.state';
import {FilterableDTO, KundeDTO, KundeService, PageableDTO} from '../../openapi/fakturierung-openapi';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';


@Injectable()
export class KundeEntitiesEffects {

  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
    private kundeService: KundeService,
    private store: Store<AppState>,
  ) {
  }

  /**
   * Effekt zum Laden eines Kunden anhand der ID, sofern es noch nicht im Store vorhanden ist.
   */
  readonly loadKundeByIdIfAbsent$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeEntitiesActions.loadKundeByIdIfAbsent),
      concatMap(action => this.store.select(KundeEntitiesSelectors.kundenById(action.kundeId)).pipe(
        take(1),
        map(kundeDto => ({action, kundeDto}))
      )),
      filter(data => !data.kundeDto),
      map(data => KundeEntitiesActions.getKundeById({
        betriebId: data.action.betriebId,
        kundeId: data.action.kundeId,
      })),
    ),
  );

  /**
   * Effekt zum Laden eines Kunden anhand der ID.
   */
  readonly getKundeById$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeEntitiesActions.getKundeById),
      concatMap(({betriebId, kundeId}) => {
        return this.kundeService.getKunde(betriebId, kundeId).pipe(
          map((kundeDto) => {
            this.logger.debug(
              'read kunde by ID succeeded. kundeDto:',
              kundeDto,
            );

            return KundeEntitiesActions.getKundeByIdSuccess({kundeDto});
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'read kunde by ID failed. kundeId:',
                kundeId,
                'error:',
                error,
              );

              return KundeEntitiesActions.getKundeByIdFailure({
                error,
              });
            }),
          )),
        );
      }),
    ),
  );

  readonly getKundeByIdFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeEntitiesActions.getKundeByIdFailure),
      tap(action => {

        let errorMsg = '';
        switch (action.error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Laden des Kontakts. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Kontakte nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden des Kontakts. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );
      })
    ),
    {dispatch: false},
  );

  /**
   * Der Effekt erlaubt asynchrones Laden von Absendern durch serverseitige Datenanfragen.
   * Dabei steuert er die Auslösung von Actions basierend auf dem Erfolg oder Misserfolg der Anfrage.
   * Er kann in unterschiedlichen Komponenten benutzt werden, um konsistente State-Management-Prozesse zu garantieren.
   *
   * @property onAction - Action Creator, der ausgeführt wird, um den Effekt zu starten
   * @property successAction - Action Creator, der ausgeführt wird, wenn die Daten erfolgreich vom Server abgerufen wurden
   * @property failureAction - Action Creator, der ausgeführt wird, wenn beim Abrufen der Daten ein Fehler aufgetreten ist
   */
  readonly readKunden$ = (
    onAction: ActionCreator<any, (props: {
      betriebId: string,
      filterableDto: FilterableDTO,
      pageableDto: PageableDTO,
    }) => ({
      betriebId: string, filterableDto: FilterableDTO, pageableDto: PageableDTO,
    } & TypedAction<any>)>,
    successAction?: ActionCreator<any, (props: { kundeDtos: KundeDTO[], }) => ({
      kundeDtos: KundeDTO[]
    } & TypedAction<any>)>,
    failureAction?: ActionCreator<any, (props: { error: MappedHttpErrorResponse, }) => ({
      error: MappedHttpErrorResponse
    } & TypedAction<any>)>,
  ) => createEffect(
    () => this.actions$.pipe(
      ofType(onAction),
      switchMap(({
                   betriebId,
                   filterableDto,
                   pageableDto,
                 }) => {

        return this.kundeService.readKunden(
          betriebId,
          filterableDto,
          pageableDto,
        ).pipe(
          switchMap(kundePageDto => {
            this.logger.debug(
              'read kunden succeeded.',
            );

            return [
              KundeEntitiesActions.readKundenSuccess({kundeDtos: kundePageDto.content}),
              KundeEntitiesActions.countKundenElementsSuccess({totalElements: kundePageDto.totalElements}),
              KundeEntitiesActions.countKundenPagesSuccess({totalPages: kundePageDto.totalPages}),
              ...(successAction ? [successAction({kundeDtos: kundePageDto.content})] : []),
            ];
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponse(error),
            switchMap(error => {
              this.logger.error(
                'read kunden failed.',
                'error:',
                error,
              );

              return [
                KundeEntitiesActions.readKundenFailed({error}),
                ...(failureAction ? [failureAction({error})] : []),
              ];
            }),
          )),
        );
      }),
    )
  );

  /**
   * Generisches Error-Handling für das Laden von Kunden.
   */
  readonly readKundenFailed$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeEntitiesActions.readKundenFailed),
      tap(({error}) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Laden von Kontakten. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Keine Kontakte gefunden.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Laden der Kontakte. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          });
      }),
    ), {dispatch: false}
  );

  /**
   * Effekt zum Erstellen eines Kunden.
   */
  readonly createKunde$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeEntitiesActions.createKunde),
      concatMap(({
                   betriebId,
                   requestDto,
                   addToInvoice,
                 }) => {
        return this.kundeService.createKunde(betriebId, requestDto).pipe(
          concatMap(kundeDto => {
            this.logger.debug(
              'create kunde succeeded. kundeId:',
              kundeDto.id,
            );

            const successActions: TypedAction<any>[] = [
              KundeEntitiesActions.createKundeSuccess({kundeDto}),
            ];

            if (addToInvoice) {
              successActions.push(FakturierungsbelegFormActions.setLeistungsempfaenger({
                kundeId: kundeDto.id,
              }));
            }

            return successActions;
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'create kunde failed.',
                'error:',
                error,
              );

              return KundeEntitiesActions.createKundeFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Success-Handling für das Erstellen eines Kunden.
   */
  readonly createKundeSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeEntitiesActions.createKundeSuccess),
      tap(() => {
        this.snackbar.open('Kontakt erfolgreich angelegt', undefined, {duration: 5000});
      }),
    ), {dispatch: false}
  );

  /**
   * Error-Handling für das Erstellen eines Kunden.
   */
  readonly createKundeFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeEntitiesActions.createKundeFailure),
      map(({error}) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Erstellen des Kontaktes. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Betrieb nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Erstellen des Kontaktes. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  /**
   * Effekt zum Aktualisieren eines Kunden.
   */
  readonly updateKunde$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeEntitiesActions.updateKunde),
      concatMap(({
                   betriebId,
                   kundeId,
                   requestDto,
                 }) => {

        return this.kundeService.updateKunde(betriebId, kundeId, requestDto).pipe(
          map(kundeDto => {
            this.logger.debug(
              'update kunde succeeded. kundeId:',
              kundeDto.id,
            );

            return KundeEntitiesActions.updateKundeSuccess({kundeDto});
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'update kunde failed.',
                'error:',
                error,
              );

              return KundeEntitiesActions.createKundeFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Success-Handling für das Aktualisieren eines Kunden.
   */
  readonly updateKundeSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeEntitiesActions.updateKundeSuccess),
      tap(() => {
        this.snackbar.open('Kontakt erfolgreich aktualisiert', undefined, {duration: 5000});
      }),
    ), {dispatch: false}
  );

  /**
   * Error-Handling für das Aktualisieren eines Kunden.
   */
  readonly updateKundeFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeEntitiesActions.updateKundeFailure),
      map(({error}) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Aktualisieren des Kontaktes. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Betrieb nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Aktualisieren des Kontaktes. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

  /**
   * Effekt zum Löschen eines Kunden.
   */
  readonly deleteKunde$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeEntitiesActions.deleteKunde),
      concatMap(({betriebId, kundeId}) => {
        return this.kundeService.deleteKunde(betriebId, kundeId).pipe(
          map(() => {
            this.logger.debug(
              'delete kunde succeeded. kundeId:',
              kundeId,
            );

            return KundeEntitiesActions.deleteKundeSuccess({kundeId});
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'delete kunde failed.',
                'error:',
                error,
              );

              return KundeEntitiesActions.deleteKundeFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Success-Handling für das Löschen eines Kunden.
   */
  readonly deleteKundeSuccess$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeEntitiesActions.deleteKundeSuccess),
      tap(() => {
        this.snackbar.open('Kontakt erfolgreich gelöscht', undefined, {duration: 5000});
      }),
    ), {dispatch: false}
  );

  /**
   * Error-Handling für das Löschen eines Kunden.
   */
  readonly deleteKundeFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeEntitiesActions.deleteKundeFailure),
      map(({error}) => {

        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Löschen des Kontaktes. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Kontakt nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
          default: {
            errorMsg = 'Fehler beim Löschen des Kontaktes. Bitte probiere es später erneut.';
          }
        }

        this.snackbar.open(
          errorMsg,
          undefined,
          {
            duration: 5000,
            panelClass: 'error',
          }
        );

        return SentryActions.captureException({
          error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

}
