import {EntityState} from '@ngrx/entity';
import {BerechnungsregelMengeMalEinzelpreisDTO, ProduktDTO} from '../../openapi/fakturierung-openapi';


type ProduktDTOEntityState = EntityState<ProduktDTO> & { ids: string[] };


export const produktEntitiesMock: ProduktDTOEntityState = {
  entities: {
    '1': {
      id: '1',
      betriebId: 'd98ec4da-47e4-4ae0-accf-d5c0e2758317',
      bezeichnung: 'Das ist ein Produkt A, das sehr gut ist.',
      nummer: 1001,
      beschreibung: 'Dies ist Produkt A',
      berechnungsregel: {} as BerechnungsregelMengeMalEinzelpreisDTO,
    },
    '2': {
      id: '2',
      betriebId: 'd98ec4da-47e4-4ae0-accf-d5c0e2758317',
      bezeichnung: 'Das Produkt B ist auch nicht schlecht.',
      nummer: 1002,
      beschreibung: 'Dies ist Produkt B',
      berechnungsregel: {} as BerechnungsregelMengeMalEinzelpreisDTO,
    },
  },
  ids: [
    '1', '2',
  ],
};

export const nachgeladenesProdukt: ProduktDTO = {
  id: '999',
  betriebId: 'd98ec4da-47e4-4ae0-accf-d5c0e2758317',
  bezeichnung: 'Osterei',
  nummer: 1,
  beschreibung: 'Bunte Ostereier in Gang 4',
  berechnungsregel: {} as BerechnungsregelMengeMalEinzelpreisDTO,
};
