import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {concatMap, of, tap} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {mappedHttpErrorResponseOperator} from '@adnova/jf-ng-components';
import {SentryActions} from '../actions/sentry.actions';
import {Store} from '@ngrx/store';
import {KundeDialogActions} from '../actions/kunde-dialog.actions';
import {KundeService} from '../../openapi/fakturierung-openapi';


@Injectable()
export class KundeDialogEffects {

  constructor(
    private actions$: Actions,
    private logger: NGXLogger,
    private kundeService: KundeService,
    private snackbar: MatSnackBar,
    private store: Store,
  ) {
  }

  /**
   * Effekt zum Lesen der nächsten freien Kundennummer.
   */
  readonly readNextKundennummer$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeDialogActions.readNextKundennummer),
      concatMap(({betriebId}) => {
        return this.kundeService.getNextKundennummer(betriebId).pipe(
          map((kundennummerResponseDto) => {
            this.logger.debug(
              'read next kundennummerResponseDto succeeded. next kundennummerResponseDto:',
              kundennummerResponseDto,
            );

            return KundeDialogActions.readNextKundennummerSuccess({
              nextKundennummer: kundennummerResponseDto.kundennummer,
            });
          }),
          catchError(error => of(error).pipe(
            mappedHttpErrorResponseOperator(error),
            map(error => {
              this.logger.error(
                'read next kundennummer failed. betriebId:',
                betriebId,
                'error:',
                error,
              );

              return KundeDialogActions.readNextKundennummerFailure({
                error,
              });
            }),
          )),
        );
      }),
    )
  );

  /**
   * Error-Handling für das Lesen der nächsten freien Kundennummer.
   */
  readonly readNextKundennummerFailure$ = createEffect(
    () => this.actions$.pipe(
      ofType(KundeDialogActions.readNextKundennummerFailure),
      map(({error}) => {
        let errorMsg = '';
        switch (error.status) {
          case 403 : {
            errorMsg = 'Fehlende Berechtigung für das Lesen der Kundennummer. ' +
              'Bitte kontaktiere deinen Steuerberater oder den Just Farming Benutzerservice.';
            break;
          }
          case 404 : {
            errorMsg = 'Betrieb nicht gefunden. Bitte probiere es später erneut.';
            break;
          }
        }

        /*
         * INFO:
         * Es kann sein, dass die nächste freie Nummer nicht gefunden wurde.
         * Dann wird der Fehler nicht angezeigt und der Nutzer kann die Kundennummer manuell eingeben.
         */
        if (errorMsg) {
          this.snackbar.open(
            errorMsg,
            undefined,
            {
              duration: 5000,
              panelClass: 'error',
            }
          );
        }

        return SentryActions.captureException({
          error,
          extras: {
            errorMsg,
          },
        });
      })
    )
  );

}
